import { useEffect, useState } from "react";
import AddButton from "../../components/buttons/add";
import Layout from "../layout.tsx/app";
import { useForm } from "react-hook-form";
import { useActivationCode } from "../../stores/activation-code";
import { request } from "../../api/config";
import { getDataWithParams } from "../../api/get-data";
import {
  HiClipboardList,
  HiExclamationCircle,
  HiKey,
  HiOutlineSearch,
  HiTrash,
  HiX,
} from "react-icons/hi";
import { Spinner } from "flowbite-react";
import Table from "../../components/tables/base";
import { parseDate } from "../../helper/date";
import { useNavigate, useLocation } from "react-router-dom";
import Pagination from "../../components/tables/pagination";
import BaseModal from "../../components/modal/base";
import { ModuleType } from "../../types/assessment-tools/module";
import { FormInput } from "../../components/forms/input";
import {
  FormSelect,
  FormSelectAsync,
  selectStyle,
} from "../../components/forms/input-select";
import moment from "moment-timezone";
import { Button } from "../../components/buttons";
import ModalDeleteConfirmation from "../../components/modal/delete-confirmation";
import { ActivationCodeType } from "../../types/activation-code";
import { PsikologType } from "../../types/psikolog";
import { SelectOptionType } from "../../types/form";
import { Pencil } from "@phosphor-icons/react";
import AsyncSelect from "react-select/async";

type FormValues = {
  modul_id: ModuleType;
  timezone: string;
  start_at: string;
  end_at: string;
  amount: number;
  type: SelectOptionType;
  psikolog_id: PsikologType;
};

type ErrorForm = {
  modul_id: [] | null;
  timezone: [] | null;
  start_at: [] | null;
  end_at: [] | null;
  amount: [] | null;
  psikolog_id: [] | null;
  type: [] | null;
};

const TodayTestCode = () => {
  const [q, setQ] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalEditPsikolog, setModalEditPsikolog] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<"create" | "edit" | undefined>(
    undefined
  );
  const [errors, setErrors] = useState<ErrorForm | null>(null);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [selected, setSelected] = useState<ActivationCodeType | null>(null);
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [selectedPsikolog, setSelectedPsikolog] = useState<PsikologType | null>(
    null
  );

  const { handleSubmit, reset, control, watch, setValue } =
    useForm<FormValues>();
  const { activationCodes, setActivationCode } = useActivationCode();

  const navigate = useNavigate();
  const location = useLocation();

  const getActivationCode = async (
    search?: string,
    searchMode: boolean = false
  ) => {
    try {
      const data = await getDataWithParams("/activation-code", {
        q: q,
        page: searchMode ? 1 : page ?? 1,
        with_paginate: 1,
        company_id: companyId,
        is_today: 1,
      });
      return data;
    } catch (error) {
      console.error("Error fetching activation code:", error);
      return { data: [] };
    }
  };

  const selectCompanies = async (inputValue: string) => {
    let params = {
      q: inputValue,
    };
    const { data } = await request.get("/select/companies", {
      params: params,
    });
    return data.data;
  };

  const handleSearch = async (input: string | undefined) => {
    setLoading(true);
    setQ(input);
    try {
      const data = await getActivationCode(input ?? "", true);
      console.log(data);

      if (data && data.data) {
        const filteredData = data.data.filter((item: ActivationCodeType) => {
          const participantMatch = item.participant
            ? item.participant
                .toLowerCase()
                .includes(input?.toLowerCase() ?? "")
            : false;
          const companyNameMatch = item.company_name
            ? item.company_name
                .toLowerCase()
                .includes(input?.toLowerCase() ?? "")
            : false;
          const codeMatch = item.code
            ? item.code.toLowerCase().includes(input?.toLowerCase() ?? "")
            : false;

          return participantMatch || companyNameMatch || codeMatch;
        });

        // Pastikan hasil pencarian diurutkan
        const sortedFilteredData = filteredData.sort(
          (a: ActivationCodeType, b: ActivationCodeType) =>
            moment(b.start_at).valueOf() - moment(a.start_at).valueOf()
        );

        setActivationCode({ ...data, data: sortedFilteredData });
      }
    } catch (error) {
      console.error("Error during search:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch(q);
    }
  };

  const handleNext = () => {
    if (page < (activationCodes?.last_page ?? 1)) {
      const nextPage = page + 1;
      setPage(nextPage);
      if (nextPage !== 1) {
        navigate(`?page=${nextPage}`);
      } else {
        navigate(`/activation-code`);
      }
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      const prevPage = page - 1;
      setPage(prevPage);
      if (prevPage !== 1) {
        navigate(`?page=${prevPage}`);
      } else {
        navigate(`/activation-code`);
      }
    }
  };

  const selectPsikolog = async (inputValue: string) => {
    try {
      let params = {
        q: inputValue,
        type: watch("type") ? watch("type").value : null,
      };
      const { data } = await request.get("/select/psikolog", {
        params: params,
      });
      return data.data?.data;
    } catch (error) {
      console.error("Error fetching psikolog:", error);
      return [];
    }
  };

  const selectPsikologById = async (id: string) => {
    try {
      let params = {
        id: id,
        type: watch("type") ? watch("type").value : null,
      };
      const { data } = await request.get("/select/psikolog", {
        params: params,
      });
      return data.data.data[0] ?? null;
    } catch (error) {
      console.error("Error fetching psikolog by ID:", error);
      return null;
    }
  };

  const handleDelete = async () => {
    setLoadingSubmit(true);
    try {
      await request.delete(`/activation-code/${selected?.id}/destroy`);
      setSelected(null);
      setModalDelete(false);
    } catch (err) {
      console.error("Error deleting activation code:", err);
    }
    reset();
    setLoadingSubmit(false);
  };

  const handleChangePsikolog = handleSubmit(async (data) => {
    setLoadingSubmit(true);
    try {
      let payload = {
        psikolog_id: data?.psikolog_id?.id,
      };
      await request.post(
        `/activation-code/${selected?.id}/change-psikolog`,
        payload
      );
    } catch (err: any) {
      console.log(err);
      setErrors(err.response.data.errors);
    } finally {
      reset();
      setModalEditPsikolog(false);
      setLoadingSubmit(false);
    }
  });

  // const handleNavigate = (code: string) => {
  //   setSelected(null); // Reset state sebelum melakukan navigasi
  //   navigate(`/activation-code/${code}`);
  // };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageFromUrl = queryParams.get("page");
    if (pageFromUrl) {
      setPage(parseInt(pageFromUrl, 10));
    }
  }, [location.search]);

  useEffect(() => {
    if (!loadingSubmit) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const data = await getActivationCode();
          if (data && data.data) {
            const sortedData = data.data.sort(
              (a: ActivationCodeType, b: ActivationCodeType) =>
                new Date(b.start_at).getTime() - new Date(a.start_at).getTime()
            );
            setActivationCode({ ...data, data: sortedData });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, loadingSubmit, companyId]);

  return (
    <Layout
      withPageTitle
      title="Kode Asesmen Hari ini"
      pageTitleContent={
        <div className="flex items-end gap-1">
          <AsyncSelect
            cacheOptions
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            loadOptions={selectCompanies}
            placeholder={"Pilih Perusahaan"}
            defaultOptions={true}
            getOptionLabel={(item: any) => item.name}
            getOptionValue={(item: any) => item.id}
            styles={selectStyle}
            isClearable={true}
            className="w-[300px]"
            onChange={(e: any) => {
              setCompanyId(e ? e.id : "");
            }}
          />
          <div className="flex items-center">
            <input
              type="text"
              className="rounded-l-lg border-gray-300"
              placeholder={"Cari disini..."}
              onChange={(e) => setQ(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={loading}
              value={q}
            />
            {q && (
              <button
                onClick={() => handleSearch("")}
                className="py-3 px-2 border border-red-600 bg-red-600 text-white"
              >
                <HiX />
              </button>
            )}
            <button
              className={`${
                loading ? "py-2 px-3" : "p-3"
              } text-lg rounded-r-lg ${
                loading
                  ? "bg-blue-500 text-white cursor-not-allowed"
                  : "bg-blue-600 text-white hover:bg-blue-700"
              }`}
              disabled={loading}
              onClick={() => handleSearch(q ?? "")}
            >
              {loading ? <Spinner size={"sm"} /> : <HiOutlineSearch />}
            </button>
          </div>
        </div>
      }
    >
      <Table>
        <Table.Thead>
          <Table.Th>#</Table.Th>
          <Table.Th>Kode Aktivasi</Table.Th>
          <Table.Th>Modul</Table.Th>
          <Table.Th>Peserta</Table.Th>
          <Table.Th>Perusahaan</Table.Th>
          <Table.Th>Psikolog</Table.Th>
          <Table.Th>Tgl. Aktif</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Opsi</Table.Th>
        </Table.Thead>
        <Table.Tbody>
          {loading ? (
            <Table.TrLoading cols={8} rows={5} />
          ) : (
            <>
              {activationCodes?.data.length === 0 ? (
                <Table.Tr>
                  <Table.Td cols={8} className="text-center py-3">
                    Tidak ada data ditemukan!
                  </Table.Td>
                </Table.Tr>
              ) : (
                <>
                  {activationCodes?.data.map((item, key) => (
                    <Table.Tr key={key}>
                      <Table.Td>
                        {(
                          key +
                          1 +
                          activationCodes.per_page *
                            (activationCodes.current_page - 1)
                        ).toString()}
                      </Table.Td>
                      <Table.Td>
                        <a
                          href={`/activation-code/${item.code}`}
                          target="_blank"
                          rel="noreferrer"
                          className="flex items-center gap-1 cursor-pointer hover:underline"
                        >
                          <span>{item.code}</span>
                          <span
                            className={`text-xs uppercase text-white font-bold px-2 rounded ${
                              item.type === "logos"
                                ? "bg-blue-600"
                                : "bg-pink-600"
                            }`}
                          >
                            {item.type === "logos" ? "L" : "D"}
                          </span>
                        </a>
                      </Table.Td>
                      <Table.Td>{item.module_name ?? "-"}</Table.Td>
                      <Table.Td>{item.participant ?? "-"}</Table.Td>
                      <Table.Td>{item.company_name ?? "-"}</Table.Td>
                      <Table.Td>
                        <div className="flex items-center gap-2">
                          {item.psikolog?.name ?? "-"}
                          <div>
                            <Pencil
                              onClick={async () => {
                                setSelected(item);
                                setModalEditPsikolog(true);
                                setValue(
                                  "psikolog_id",
                                  item.psikolog
                                    ? await selectPsikologById(
                                        item.psikolog?.id
                                      )
                                    : null
                                );
                              }}
                              className="text-blue-600 cursor-pointer"
                            />
                          </div>
                        </div>
                      </Table.Td>
                      <Table.Td>
                        {item.start_at
                          ? item.end_at &&
                            `${parseDate(item.start_at)} - ${parseDate(
                              item.end_at
                            )}`
                          : "-"}
                      </Table.Td>
                      <Table.Td>
                        <span
                          className={`${
                            item.status === "belum digunakan" &&
                            "text-yellow-400"
                          } ${item.status === "proses" && "text-blue-600"} ${
                            item.status === "selesai" && "text-green-500"
                          } p-1 rounded text-xs inline-block uppercase`}
                        >
                          {item.status}
                        </span>
                      </Table.Td>
                      <Table.Td>
                        <>
                          {item.status === "belum digunakan" ? (
                            <div className="flex items-center gap-1">
                              <div
                                onClick={() =>
                                  window.open(
                                    `/activation-code/${item.code}`,
                                    "_blank"
                                  )
                                }
                                className="bg-blue-600 text-white p-1 cursor-pointer hover:bg-blue-700 rounded"
                              >
                                <HiExclamationCircle />
                              </div>
                              <div
                                onClick={() => {
                                  setModalDelete(true);
                                  setSelected(item);
                                }}
                                className="bg-red-600 text-white p-1 cursor-pointer hover:bg-red-700 rounded"
                              >
                                <HiTrash />
                              </div>
                              <div className="bg-green-600 text-white p-1 cursor-pointer hover:bg-green-700 rounded">
                                <HiKey />
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center gap-1">
                              <div
                                onClick={() =>
                                  window.open(
                                    `/activation-code/${item.code}`,
                                    "_blank"
                                  )
                                }
                                className="bg-blue-600 text-white p-1 cursor-pointer hover:bg-blue-700 rounded"
                              >
                                <HiExclamationCircle />
                              </div>

                              <div
                                onClick={() =>
                                  window.open(
                                    `/activation-code/${item.code}/sheet`,
                                    "_blank"
                                  )
                                }
                                className="bg-gray-600 text-white p-1 cursor-pointer hover:bg-gray-700 rounded"
                              >
                                <HiClipboardList />
                              </div>
                              <div
                                onClick={() =>
                                  window.open(
                                    `/activation-code/${item.code}/access`,
                                    "_blank"
                                  )
                                }
                                className="bg-green-600 text-white p-1 cursor-pointer hover:bg-green-700 rounded"
                              >
                                <HiKey />
                              </div>
                            </div>
                          )}
                        </>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </>
              )}
            </>
          )}
        </Table.Tbody>
      </Table>
      <Pagination
        currentPage={activationCodes?.current_page ?? 1}
        totalPage={activationCodes?.last_page ?? 1}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />

      <BaseModal
        title={`Ubah Psikolog #${selected?.code}`}
        isOpen={modalEditPsikolog}
        close={() => setModalEditPsikolog(false)}
      >
        <FormSelectAsync
          label="Pilih Psikolog"
          name="psikolog_id"
          control={control}
          loadOption={selectPsikolog}
          defaultValue={selectedPsikolog}
          optionLabel={(option: PsikologType) => `${option.fullname}`}
          optionValue={(option: PsikologType) => `${option.id}`}
          error={errors?.modul_id}
        />

        <div className="mt-3 flex items-center justify-end">
          <Button className="px-8" onClick={handleChangePsikolog}>
            {loadingSubmit ? <Spinner /> : "Simpan"}
          </Button>
        </div>
      </BaseModal>

      <ModalDeleteConfirmation
        isOpen={modalDelete}
        close={() => setModalDelete(false)}
        subTitle="kode aktivasi"
        name={selected?.code}
        loading={loadingSubmit}
        action={handleDelete}
      />
    </Layout>
  );
};

export default TodayTestCode;
