const Today = () => {
  const date = new Date();
  const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const dayName = days[date.getDay()];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return (
    <>
      Hari ini{" "}
      <strong className="font-semibold bg-blue-100 px-1 rounded">
        <span className="">{dayName}, </span>
        <span className="">
          {day} {month} {year}
        </span>
      </strong>
    </>
  );
};

export default Today;
