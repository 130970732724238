import { create } from "zustand";
import { request } from "../api/config";

interface DashboardStore {
  data: { today_test: number } | undefined;
  getData: () => Promise<void>;
}

const useDashboardStore = create<DashboardStore>((set) => ({
  data: undefined,
  getData: async () => {
    try {
      const { data } = await request.get("/dashboard");
      set({ data: data.data });
    } catch (error) {}
  },
}));

export default useDashboardStore;
