import { useEffect, useState } from "react";
import { useSession } from "../../stores/session";
import Layout from "../layout.tsx/app";
import useDashboardStore from "../../stores/dashboard.store";
import { ChalkboardTeacher, Command, Exam } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import Today from "../../components/clock/today";

const Homepage = () => {
  const navigate = useNavigate();

  const { me, notification } = useSession();
  const { getData, data } = useDashboardStore();

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Layout>
      <div>
        <div className="mt-6 w-full max-w-4xl mx-auto py-6 md:flex gap-2 items-center">
          <img src="dashboard.gif" style={{ maxHeight: "350px" }} alt="" />
          <div className="w-full">
            <p className="text-3xl text-center md:text-left">
              Hai <strong>{me?.name}</strong> 👋
            </p>
            <div className="mt-2 w-full max-w-md">
              {/* <p>Selamat Datang di Panel Admin Logos</p> */}
              <Today />
            </div>

            <div className="mt-3 grid grid-cols-2 md:grid-cols-3 gap-3">
              <div
                className="border rounded-lg p-3 text-sm hover:bg-purple-50 border-purple-200 cursor-pointer relative"
                onClick={() => navigate("/activation-code/today")}
              >
                <Exam weight="duotone" size={32} className="text-purple-700" />
                <span className="text-purple-700">Asesmen hari ini</span>
                <div className="absolute -top-2 -right-1 flex items-center justify-center h-8 w-8 rounded-full bg-purple-100">
                  <strong className="text-purple-700 text-lg">
                    {data?.today_test}
                  </strong>
                </div>
              </div>

              <div
                className="border rounded-lg p-3 text-sm hover:bg-teal-50 border-teal-200 cursor-pointer relative"
                onClick={() => navigate("/asesmen")}
              >
                <Command weight="duotone" size={32} className="text-teal-700" />
                <span className="text-teal-700">Pendaftaran Asesmen</span>
                <div className="absolute -top-2 -right-1 flex items-center justify-center h-8 w-8 rounded-full bg-teal-100">
                  <strong className="text-teal-700 text-lg">
                    {notification.new_assessment ?? 0}
                  </strong>
                </div>
              </div>

              <div
                className="border rounded-lg p-3 text-sm hover:bg-pink-50 border-pink-200 cursor-pointer relative"
                onClick={() => navigate("/company")}
              >
                <ChalkboardTeacher
                  weight="duotone"
                  size={32}
                  className="text-pink-700"
                />
                <span className="text-pink-700">Client Management</span>
                <div className="absolute -top-2 -right-1 flex items-center justify-center h-8 w-8 rounded-full bg-pink-100">
                  <strong className="text-pink-700 text-lg">
                    {notification.new_companies ?? 0}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Homepage;
